import React, { Component } from "react";
import { graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import grievanceStyle from "ps-assets/jss/grievanceStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import classNames from "classnames";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSnackbar from "components/Snackbar/Snackbar";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

var apiBaseUrl = process.env.API_URL;

// var table_data = [];
class claimproposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policy_no: ``,
      pan_no: ``,
      policy_holder_name: ``,
      dob: ``,
      customer_name: ``,
      address: ``,
      status: ``,
      response_data: [],
      details: {}
    };
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true
      },
      overrides: {
        MUIDataTableSelectCell: {
          root: {
            display: `none`
          }
        },
        MuiTableCell: {
          body: {
            textAlign: `left`
          },
          head: {
            textAlign: `center`
          }
        },
        MUIDataTable: {
          root: {
            backgroundColor: `red`
          },
          paper: {
            boxShadow: `none`,
            paddingBottom: `20px`
          },
          responsiveScroll: { maxHeight: `auto !important` }
        },
        MUIDataTableHeadCell: {
          root: {
            fontSize: 17,
            color: `black`
          }
        }
      }
    });
  handleChange = e => {
    if (e.target.id === `mobile`) {
      if (!isNaN(e.target.value) && e.target.value.length <= 10) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else if (
      e.target.id === `customer_name` ||
      e.target.id === `policy_holder_name`
    ) {
      if ((`nice`, e.target.value.search(`^[a-zA-Z. ']*$`) === 0)) {
        this.setState({ [e.target.id]: e.target.value });
      }
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };
  handleSubmit = async e => {
    var rowDetails = [];
    e.preventDefault();
    if (
      this.state.policy_no == `` &&
      this.state.pan_no == `` &&
      this.state.policy_holder_name == `` &&
      this.state.address == `` &&
      this.state.dob == `` &&
      this.state.customer_name == ``
    ) {
      this.setState({
        status: `error`
      });
    } else {
      let url = `${apiBaseUrl}form/claim-proposal?`;

      let { policy_no, pan_no, policy_holder_name, address, dob } = this.state;

      var queryString = this.objToQueryString({
        policy_no: policy_no,
        pan_no: pan_no,
        policy_holder_name: policy_holder_name,
        address: address,
        dob: dob,
        pageNo: `1`,
        totalRecord: `10`
      });
      url += queryString;
      let res = await fetch(url);
      let resJson = await res.json();

      if (res.status !== 200) {
        this.setState({
          status: `fail`
        });
      } else if (resJson) {
        if (!resJson.error) {
          this.setState({
            policy_no: ``,
            pan_no: ``,
            policy_holder_name: ``,
            dob: ``,
            customer_name: ``,
            address: ``,
            status: `success`,
            response_data: resJson
          });
          resJson.success.map(data => {
            if (
              (data.policy_no != `` &&
                data.customer_name != `` &&
                data.unclaimed_premium_amount != undefined) ||
              data.claims_number != ``
            ) {
              var complete_data = {};
              complete_data[`policy_no`] = data.policy_no;
              complete_data[`customer_name`] = data.customer_name;
              complete_data[`claims_number`] =
                data.claims_number === `` ? `-` : data.claims_number;
              complete_data[`unclaimed_premium_amount`] =
                data.unclaimed_premium_amount;
              rowDetails.push(complete_data);
            }
          });
          var details = {};
          details.rows = rowDetails;
          this.setState({
            details: details
          });
        } else {
          this.setState({
            status: `fail`
          });
        }
      }
    }
  };

  handleDate = value => {
    this.setState({ dob: value });
  };

  handleDateString = e => {
    var dob = e;
    if (this.state.dob.length > 10) {
      this.setState({
        dob: this.state.dob.substr(0, 10)
      });
    } else {
      this.setState({
        dob: dob
      });
    }
  };
  setStatus = () => {
    this.setState({
      status: ``
    });
  };
  objToQueryString = obj => {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj[key] && obj[key].trim() != ``)
        keyValuePairs.push(key + `=` + obj[key]);
    }
    return keyValuePairs.join(`&`);
  };

  render() {
    const columns = [
      {
        name: `policy_no`,
        label: `Policy Number`,
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: `customer_name`,
        label: `Customer Name`,
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: `claims_number`,
        label: `Claims Number`,
        options: {
          filter: true,
          sort: false
        }
      },
      {
        name: `unclaimed_premium_amount`,
        label: `Amount`,
        options: {
          filter: true,
          sort: false
        }
      }
    ];
    const options = {
      selectableRows: false,
      filter: false,
      search: false,
      sort: false,
      download: false,
      print: false,
      viewColumns: false,
      responsive: `scroll`
    };

    const { classes, data } = this.props;
    var image2 =
      data.allStrapiImages.edges[1].node.content.childImageSharp.fluid;
    return (
      <Layout image2={image2} small={true}>
        <Helmet key="helmetTags">
          <title>Claim Proposal</title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link
              rel="canonical"
              href="https://www.starhealth.in/claim-proposal"
            />
            <meta name="title" content="Claim Proposal" />
            <meta name="twitter:title" content="Claim Proposal" />
            <meta property="og:title" content="Claim Proposal" />
            <meta property="og:url" content="https://www.starhealth.in/claim-proposal"/>
            <meta name="Claim Proposal" />
            <meta property="twitter:url" content="https://www.starhealth.in/claim-proposal" />
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: `100px` }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer className={classes.container}>
                <GridItem
                  md={9}
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                >
                  <h2 className={`${classes.title} ${classes.description}`}>
                    Unclaimed Amount
                  </h2>
                  <div>
                    <form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Policy No"
                            id="policy_no"
                            formControlProps={{
                              fullWidth: true
                            }}
                            onChange={this.handleChange}
                            value={this.state.policy_no}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="PAN of the policy holder"
                            id="pan_no"
                            formControlProps={{
                              fullWidth: true
                            }}
                            onChange={this.handleChange}
                            value={this.state.pan_of_the_policy_holder}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Name of the policy holder"
                            id="policy_holder_name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            onChange={this.handleChange}
                            value={this.state.policy_holder_name}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={6}
                          md={6}
                          style={{ paddingTop: `11px` }}
                          className={` ${classes.description}`}
                        >
                          <FormControl fullWidth>
                            <Datetime
                              style={{ marginTop: `3px`, color: `#999` }}
                              timeFormat={false}
                              id='dob'
                              inputProps={{
                                placeholder: `Date of birth of the policy holder`
                              }}
                              className={`${classes.description} `}
                              value={this.state.dob}
                              onClick={moment =>
                                this.handleDate(moment.format(`DD/MM/YYYY`))
                              }
                              onChange={moment =>
                                this.handleDateString(moment.format(`DD/MM/YYYY`))
                              }
                              closeOnSelect={true}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Customer name"
                            id="customer_name"
                            formControlProps={{
                              fullWidth: true
                            }}
                            onChange={this.handleChange}
                            value={this.state.customer_name}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Address"
                            id="address"
                            formControlProps={{
                              fullWidth: true
                            }}
                            disabled={
                              this.state.policy_holder_name.length === 0 &&
                              this.state.policy_no.length === 0 &&
                              this.state.customer_name.length === 0 &&
                              this.state.dob.length === 0 &&
                              this.state.pan_no.length === 0
                            }
                            onChange={this.handleChange}
                            value={this.state.address}
                          />
                        </GridItem>

                        <span
                          style={{
                            float: `left`,
                            fontSize: `13px`,
                            paddingLeft: `20px`,
                            paddingTop: `20px`
                          }}
                        >
                          Please fill any field
                        </span>
                        <GridItem
                          xs={12}
                          sm={4}
                          md={4}
                          className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                        >
                          <br />
                          <Button
                            color="primary"
                            round
                            type={`submit`}
                            style={{ marginRight: 100 }}
                          >
                            Search
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </div>
                </GridItem>
              </GridContainer>
              {this.state.status == `success` &&
                this.state.response_data.success.length > 0 && (
                <GridContainer className={classes.container}>
                  <GridItem
                    md={9}
                    className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                  >
                    <div style={{ maxWidth: `100%` }}>
                      <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          title={`Results`}
                          data={this.state.details.rows}
                          columns={columns}
                          options={options}
                        />
                      </MuiThemeProvider>
                    </div>
                  </GridItem>
                </GridContainer>
              )}

              {this.state.status == `fail` && (
                <CustomSnackbar
                  open={true}
                  duration={6000}
                  status={`error`}
                  message={`No records found`}
                  flag={this.setStatus}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withStyles(grievanceStyle)(claimproposal);

claimproposal.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export const termsBenefit = graphql`
  query claimproposal {
    allStrapiImages(filter: { category: { eq: "claim_proposal" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
